export const config = {
    RPC_URL: 'https://rpc-meme-1.meme.sx',
    REST_URL: 'https://api-meme-1.meme.sx',
    EXPLORER_URL: 'https://explorer.meme.sx',
    STAKING_URL: 'https://wallet.meme.sx/stake',
    NETWORK_NAME: 'Meme',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'meme-1',
    CHAIN_NAME: 'Meme',
    COIN_DENOM: 'MEME',
    COIN_MINIMAL_DENOM: 'umeme',
    COIN_DECIMALS: 6,
    PREFIX: 'meme',
    COIN_TYPE: 118,
    COINGECKO_ID: '-',
    DEFAULT_GAS: 250000,
    GAS_PRICE_STEP_LOW: 0.0025,
    GAS_PRICE_STEP_AVERAGE: 0.035,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
};




